<template>
  <div class="fill-height">
    <v-overlay color="primary" :absolute="true" v-if="loading">
      <v-progress-circular
        indeterminate
        color="accent"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <div class="py-2 px-4 elevation-2 page-error-bar" v-if="showError">
      <v-icon color="white">mdi-alert-outline</v-icon>
      <span class="white--text pl-3">{{ error }}</span>
    </div>
    <v-container fluid class="container-main">
      <v-row justify="start">
        <v-col cols="12" class="pb-1">
          <h2>Admin Users</h2>
          <p class="text-body-1 font-italic text--secondary mb-0">
            Manage users with admin permissions
          </p>
        </v-col>
        <v-col cols="12">
          <v-system-bar color="accent" height="5"></v-system-bar>
          <div class="d-flex">
            <v-text-field
              class="pb-2"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </div>
          <v-form @submit.prevent="saveNewUser()" ref="newUserForm">
            <v-data-table
              :headers="usersHeader"
              :items="localUsers"
              dense
              :search="search"
              :items-per-page="25"
              :footer-props="{ itemsPerPageOptions: [25, 50, 100] }"
            >
              <template v-slot:item.action="{ item }">
                <v-btn
                  v-if="userCount > 1"
                  icon
                  small
                  @click="removeLaunch(item.userName)"
                  color="error"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <div v-else>
                  <span class="font-italic">Cannot remove last user</span>
                </div>
              </template>
              <template v-slot:body.append>
                <tr v-if="showAddUserSection">
                  <td>
                    <v-text-field
                      label="Email Address"
                      v-model="newEmail"
                      type="text"
                      :rules="emailRules"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-select
                      v-model="newRole"
                      :items="validRoles"
                      label="Role"
                      :rules="roleRules"
                    ></v-select>
                  </td>
                  <td>
                    <v-btn icon large color="primary" type="submit">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr v-else>
                  <td colspan="3" class="selectable" @click="startSaveUser()">
                    <div outlined class="d-flex justify-center wide">
                      <v-icon>mdi-plus</v-icon>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <r-dialog v-model="removeDialog" title="Removing Permissions">
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="text-body-1 text--primary">{{ dialogDisplay }}</p>
        <span class="text-body-2">Are you sure?</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray" text @click="removeDialog = false"> Cancel </v-btn>
        <v-btn color="error" text @click="removeFinish()"> Remove </v-btn>
      </v-card-actions>
    </r-dialog>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import * as disp from "../../utility/display.js";

// @ is an alias to /src
export default {
  name: "AdminUsers",
  data: () => ({
    loading: false,
    search: "",
    localUsers: [],
    error: "",
    removeDialog: false,
    removeUserName: "",
    dialogDisplay: "",
    showAddUserSection: false,
    newEmail: "",
    newRole: "",
    validRoles: ["admin"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    roleRules: [(v) => !!v || "Role is required"],
  }),
  components: {},
  mounted() {
    this.buildList();
    this.loadEnvironment().then(this.buildList);
  },
  watch: {},
  computed: {
    ...mapFields("environment", ["adminUsers"]),
    showError() {
      return !disp.IsNullorWhitespace(this.error);
    },
    usersHeader() {
      return [
        { text: "Email", sortable: true, value: "userName" },
        { text: "Role", sortable: true, value: "permission" },
        { text: "Remove", sortable: false, value: "action" },
      ];
    },
    userCount() {
      return this.localUsers?.length ?? 0;
    },
  },
  methods: {
    ...mapActions("environment", ["loadEnvironment", "addUser", "removeUser"]),
    buildList() {
      let tempList = [];
      this.adminUsers.forEach((r) => {
        tempList.push(r);
      });

      this.localUsers = tempList;
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    removeLaunch(item) {
      this.dialogDisplay = `You are about to remove permissions for ${item}.`;
      this.removeUserName = item;
      this.removeDialog = true;
    },
    async removeFinish() {
      try {
        this.loading = true;
        this.removeDialog = false;
        await this.removeUser({ userName: this.removeUserName });
        await this.loadEnvironment();
        this.buildList();
      } finally {
        this.loading = false;
      }
    },
    startSaveUser() {
      this.showAddUserSection = true;
    },
    async saveNewUser() {
      if (!this.$refs.newUserForm.validate()) {
        return;
      }
      try {
        this.loading = true;
        await this.addUser({
          userName: this.newEmail,
          permission: this.newRole,
        });
        this.showAddUserSection = false;
        this.newEmail = "";
        this.newRole = "";
        await this.loadEnvironment();
        this.buildList();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.selectable {
  cursor: pointer;
}
</style>